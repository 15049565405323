import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"

const Projects = ({ projects = [] }) => {
  return (
    <Container>
      {projects.map(({ id, image, url, title, type: subtitle }) => {
        const pathToImage = getImage(image.gatsbyImageData)

        return (
          <div key={id} className="card">
            <div className="img-container">
              <a href={url} target="_blank" rel="noreferrer">
                <GatsbyImage
                  image={pathToImage}
                  alt={title}
                  className="card-img"
                />
              </a>
            </div>
            <div className="card-content">
              <a href={url}>
                <h4 className="card-title">{title}</h4>
              </a>
              <p className="card-subtitle">{subtitle}</p>
            </div>
          </div>
        )
      })}
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 5rem;

  .card {
    margin-bottom: 3rem;
  }
  .img-container {
    margin-bottom: 1.25rem;
  }
  .card-img {
    width: 100%;
    transition: 0.25s;
    &:hover {
      opacity: 0.8;
    }
  }
  .card-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .card-title {
    margin-bottom: 0.5rem;
    font-weight: 200;
    transition: 0.125s;
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
  .card-subtitle {
    font-weight: 100;
    text-transform: lowercase;
    letter-spacing: 0.05rem;
  }

  @media (min-width: 800px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr 1fr;

    .card {
      padding: 0 1rem;
      margin-bottom: 4rem;
    }
  }
`

export default Projects
