import React from "react"
import styled from "styled-components"
// import Filter from "../components/Filter"
// import Title from "../components/Title"
import Projects from "../components/Projects"
import { graphql, useStaticQuery } from "gatsby"
import "../assets/css/index.css"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const query = graphql`
  {
    allContentfulProjects(sort: { fields: updatedAt, order: DESC }) {
      nodes {
        id
        title
        type
        url
        content {
          tags
        }
        image {
          gatsbyImageData(layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
        }
      }
    }
  }
`

const Portfolio = () => {
  const {
    allContentfulProjects: { nodes: projects },
  } = useStaticQuery(query)

  return (
    <Layout>
      <SEO title="Portfolio" />
      <Container className="section">
        <div className="section-center">
          {/* <Title title="Portfolio" /> */}
          {/* <Filter projects={projects} /> */}
          <Projects projects={projects} />
        </div>
      </Container>
    </Layout>
  )
}

const Container = styled.section`
  width: 100%;
`

export default Portfolio
